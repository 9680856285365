:root {
  /* Pagefind overrides */
  --pagefind-ui-background: #ffffff;
  --pagefind-ui-border-radius: 8px;
  --pagefind-ui-border-width: 2px;
  --pagefind-ui-border: theme('colors.slate.100');
  --pagefind-ui-font: theme('fontFamily.sans');
  --pagefind-ui-image-border-radius: 8px;
  --pagefind-ui-image-box-ratio: 16 / 9;
  --pagefind-ui-primary: theme('colors.primary.500');
  --pagefind-ui-scale: 1;
  --pagefind-ui-tag: theme('colors.slate.100');
  --pagefind-ui-text: theme('colors.primary.900');
}

form.pagefind-ui__form {
  @apply relative;

  &:focus-within button.pagefind-ui__search-clear {
    @apply visible
  }
}

.pagefind-ui__search-input[type=text] {
  @apply
    bg-[url(/images/icons/search-bg.svg)]
    bg-center
    bg-contain
    bg-left
    bg-no-repeat
    bg-transparent
    bg-white
    block
    border-none
    disabled:cursor-not-allowed
    disabled:hover:ring-slate-200
    disabled:opacity-50
    duration-100
    focus-visible:outline-none
    focus-visible:ring-2
    focus-visible:ring-inset
    focus-visible:ring-primary-500
    focus:outline-none
    focus:ring-2
    focus:ring-inset
    focus:ring-primary-500
    focus:shadow-lg
    focus:shadow-slate-950/20
    hover:ring-1
    hover:ring-inset
    hover:ring-slate-400
    min-h-[2.75em]
    outline-none
    pl-[2.625em]
    placeholder:text-slate-300
    px-[1em]
    py-[0.5em]
    relative
    ring-1
    ring-inset
    ring-slate-200
    rounded-md
    w-full
    z-[51]
}

button.pagefind-ui__search-clear {
  @apply
    -translate-y-1/2
    absolute
    after:[filter:invert(52%)_sepia(6%)_saturate(2467%)_hue-rotate(209deg)_brightness(94%)_contrast(86%)]
    after:bg-[length:60%]
    after:bg-[url(/images/icons/x.svg)]
    after:bg-center
    after:bg-no-repeat
    before:bg-slate-50
    before:duration-100
    before:opacity-0
    before:rounded-full
    before:scale-75
    hover:before:opacity-100
    hover:before:scale-100
    invisible
    overflow-hidden
    pseudo:absolute
    pseudo:inset-0
    right-3
    select-none
    size-5
    text-transparent
    top-1/2
    z-[52]
}

.pagefind-ui__drawer {
  @apply
    absolute
    bg-white
    max-h-[75vh]
    left-0
    overflow-y-auto
    rounded-lg
    top-[calc(100%+0.5rem)]
    w-full
    z-[51];

  &:not(.pagefind-ui__hidden) {
    @apply ring-1 ring-slate-950/[0.03] shadow-lg shadow-slate-950/20
  }
}

.pagefind-ui__results-area { @apply p-5 }

.pagefind-ui__message { @apply text-sm }

.pagefind-ui__results { @apply divide-y border-b mb-5 last:mb-0 last:border-b-0 }

.pagefind-ui__result { @apply flex max-sm:flex-col gap-5 py-5 }

.pagefind-ui__result-thumb { @apply aspect-video min-w-[10rem] w-[10rem] }

.pagefind-ui__result-image { @apply rounded-md aspect-video }

.pagefind-ui__result-excerpt { @apply text-sm mt-[0.25em] }

.pagefind-ui__result-nested {
  @apply
    pl-8
    mt-4
    relative
    before:absolute
    before:left-1
    before:-top-1
    before:size-6
    before:bg-[url(/images/icons/arrow-down-curve-right.svg)]
    before:bg-[length:50%]
    before:bg-no-repeat
    before:bg-center;

  .pagefind-ui__result-title { @apply text-sm }

  .pagefind-ui__result-excerpt { @apply text-xs }
}

.pagefind-ui__button[type=button] {
  @apply
    before:[filter:invert(42%)_sepia(20%)_saturate(2699%)_hue-rotate(217deg)_brightness(102%)_contrast(109%)]
    before:bg-[url(/images/icons/arrow.svg)]
    before:bg-center
    before:bg-contain
    before:bg-no-repeat
    before:duration-100
    before:inline-block
    before:rotate-90
    before:size-[0.625em]
    duration-100
    flex
    font-medium
    gap-2
    hover:text-primary-700
    items-center
    leading-tight
    mx-auto
    p-2
    text-primary-500;

  &:hover::before {
    filter: invert(22%) sepia(53%) saturate(3820%) hue-rotate(249deg) brightness(85%) contrast(97%);
  }
}
