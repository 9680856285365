code[class*='language-'],
pre[class*='language-'] {
  @apply font-medium text-slate-200 leading-[1.5em];
}

pre code.hljs { @apply block; }

.hljs-tag { color: #c03089; }
.hljs-keyword { color: #6cd1e2; }
.hljs-variable.language_ { color: #ffba52; }
.hljs-string { color: #64f08e; }
.hljs-params, .hljs-number { color: #ff8c5a; }

.hljs-tag .hljs-name {
  color: #ff6183;
}

.hljs-title.class_,
.hljs-property {
  color: #e374ff;
}

.hljs-title.function_,
.hljs-attr {
  color: #7f9fff;
}

.hljs-built_in,
.language-json .hljs-keyword {
  color: #ff7fe3;
}
