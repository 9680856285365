/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */
@import "vendor/calendly.css";
@import "vendor/hljs.css";
@import "vendor/pagefind.css";
@import 'tippy.js/animations/shift-toward.css';

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

[data-tippy-root] { z-index: 900 !important; }

[data-tippy-root] .tippy-box {
  @apply bg-slate-950 rounded-lg;

  ul {
    @apply ml-[1.25em];

    li { @apply list-item; }
  }

  .tippy-content { @apply text-white; }
  p { @apply pb-[1em]; }
}

[data-tippy-root] .tippy-content { @apply px-[0.75em] py-[0.5em]; }
[data-tippy-root] .tippy-arrow { @apply bg-slate-950; }

[data-tippy-root][data-theme='light'] .tippy-box {
  @apply bg-white;

  .tippy-content { @apply text-slate-900; }
  .tippy-arrow { @apply bg-white; }
}

@layer base {
  :root {
    --nav-height: theme('spacing.20');
    --color-deliverable: theme('colors.green.500');
    --color-undeliverable: theme('colors.red.500');
    --color-risky: theme('colors.yellow.500');
    --color-unknown: theme('colors.blue.500');
    --verifier-result-color: var(--color-unknown);
  }

  /* Default to `display: block` for images, SVGs, and custom tags */
  img, svg, text-block { @apply block; }
  svg { @apply w-full; }
  h1, h2, h3, h4, h5, h6 { @apply font-bold }
  h1 { @apply md:text-5xl text-4xl }
  h2 { @apply md:text-5xl text-4xl }
  h3 { @apply md:text-3xl text-2xl }
  h4 { @apply md:text-2xl text-xl }
 .h1 { @apply md:!text-5xl !text-4xl }
 .h2 { @apply md:!text-5xl !text-4xl }
 .h3 { @apply md:!text-3xl !text-2xl }
 .h4 { @apply md:!text-2xl !text-xl }
  p { @apply text-slate-700 }
  .body-text { @apply text-lg; }
  .subheading { @apply text-lg font-medium; }

  page-main {
    & > header card {
      p, ul, ol { @apply text-slate-700 drop-shadow-none; }
    }
  }

  mark { @apply bg-yellow-200 }

  strong { @apply font-extrabold }
}

@layer components {
  .button {
    @apply
      [&>icon]:data-[loading=true]:duration-0
      [&>icon]:data-[loading=true]:opacity-0
      before:absolute
      before:bg-[url(/images/icons/loading-spinner.svg)]
      before:bg-center
      before:bg-contain
      before:bg-no-repeat
      before:opacity-0
      before:pointer-events-none
      before:size-[1.25em]
      before:z-[1]
      data-[loading=true]:before:animate-spin-absolute
      data-[loading=true]:before:opacity-100
      data-[loading=true]:text-transparent
      disabled:cursor-not-allowed
      disabled:opacity-75
      duration-100
      focus-visible:outline-none
      focus-visible:ring
      focus-visible:ring-offset-0
      focus-visible:ring-offset-transparent
      focus-visible:ring-primary-500/50
      focus:outline-none
      focus:ring-0
      font-semibold
      inline-flex
      items-center
      justify-center
      min-h-[3.5em]
      px-[1.5em]
      py-[0.5em]
      relative
      rounded-[0.375em]
      text-center
      text-white
      transition;

    &.api {
      @apply
        active:bg-primary-950
        bg-primary-800
        focus-visible:bg-primary-900
        focus-visible:ring-primary-800/50
        focus:bg-primary-900
        hover:bg-primary-900;
    }

    &.green {
      @apply
        active:bg-green-600
        bg-green-400
        focus-visible:bg-green-500
        focus-visible:ring-green-400/50
        focus:bg-green-500
        hover:bg-green-500;
    }

    &.widget {
      @apply
        active:bg-yellow-600
        bg-yellow-400
        focus-visible:bg-yellow-500
        focus-visible:ring-yellow-500/50
        focus:bg-yellow-500
        hover:bg-yellow-500;
    }

    &.deliverability {
      @apply
        active:bg-blue-700
        bg-blue-500
        focus-visible:bg-blue-600
        focus-visible:ring-blue-500/50
        focus:bg-blue-600
        hover:bg-blue-600;
    }

    &.secondary {
      @apply
        active:bg-primary-400/25
        active:ring-1
        active:ring-inset
        active:ring-primary-500
        bg-transparent
        focus-visible:bg-primary-400/15
        focus:bg-primary-400/15
        focus:ring-1
        focus:ring-inset
        focus:ring-primary-500
        hover:bg-primary-400/15
        ring-1
        ring-inset
        ring-primary-500
        text-primary-900;
    }

    & > span { @apply leading-none; }
  }

  /* Styles are defined here so that code snippets in blog posts can use them */
  code-snippet {
    @apply bg-code flex;

    pre { @apply bg-code grow pb-30px px-5 outline-none whitespace-pre; }

    ::selection { @apply bg-slate-500/15; }
  }

  code-scroll-container {
    @apply flex overflow-x-auto w-full;
    mask-image: linear-gradient(to right,
        transparent,
        black 1.25rem,
        black calc(100% - 1.25rem),
        transparent 100%);
  }

  .dropdown-button {
    @apply hover-bg group-data-[dropdown-active-value=true]/dropdown:bg-slate-100;

    &--dark {
      @apply hover-bg-dark group-data-[dropdown-active-value=true]/dropdown:bg-primary-200/20;
    }
  }

  graphic {
    @apply flex items-center justify-center;

    img, svg { @apply w-full; }
  }

  icon {
    &.checkmark, &.x {
      @apply rounded-full;

      svg { @apply h-3/5 w-3/5; }

      &.gray {
        @apply bg-slate-500/30;

        svg { @apply fill-slate-500; }
      }
    }

    &.checkmark:not(.gray) {
      @apply bg-green-500/25;

      svg { @apply fill-green-500; }
    }

    &.x:not(.gray) {
      @apply bg-red-500/25;

      svg { @apply fill-red-500; }
    }

    &.question-mark {
      @apply bg-blue-500/25;

      svg { @apply fill-blue-500; }
    }
  }

  .input {
    @apply
      bg-transparent
      block
      border-none
      disabled:cursor-not-allowed
      disabled:hover:ring-slate-200
      disabled:opacity-50
      duration-100
      focus-visible:outline-none
      focus-visible:ring-2
      focus-visible:ring-inset
      focus-visible:ring-primary-500
      focus:outline-none
      focus:ring-2
      focus:ring-inset
      focus:ring-primary-500
      hover:ring-1
      hover:ring-inset
      hover:ring-slate-400
      min-h-[2.75em]
      outline-none
      placeholder:text-slate-300
      py-[0.5em]
      px-[1em]
      ring-1
      ring-inset
      ring-slate-200
      rounded-md
      transition-shadow
      w-full;
  }

  logo {
    &[data-company='hotmart'],
    &[data-company='y_adjust'] {
      @apply translate-y-[-10%];
    }

    &[data-company='blablacar'],
    &[data-company='wework'] {
      @apply translate-y-[-8%];
    }

    &[data-company='sodexo'] { @apply translate-y-[-20%]; }
  }

  .popout {
    @apply
      absolute
      before:absolute
      before:bottom-full
      before:content-['']
      before:h-8px
      before:left-0
      before:w-full
      bg-white
      block
      group-data-[dropdown\-active\-value=true]/dropdown:opacity-100
      group-data-[dropdown\-active\-value=true]/dropdown:pointer-events-auto
      group-data-[dropdown\-active\-value=true]/dropdown:translate-y-0
      duration-200
      ease-in-out-expo
      group-hover/dropdown-hover:opacity-100
      group-hover/dropdown-hover:pointer-events-auto
      group-hover/dropdown-hover:translate-y-0
      mt-8px
      opacity-0
      p-10px
      pointer-events-none
      right-0
      ring-1
      ring-inset
      ring-slate-50
      rounded-lg
      shadow-lg
      shadow-slate-800/20
      transition
      -translate-y-6px
      z-1;
  }

  .bg-gradient-radial-darker,
  .bg-gradient-radial-dark,
  .bg-gradient-radial-purple {
    .colorable-fill.becomes-white { @apply fill-white; }
    .colorable-fill.becomes-purple--light { @apply fill-primary-300; }
    .colorable-fill.becomes-purple { @apply fill-primary-500; }
    .colorable-fill.becomes-purple--dark { @apply fill-primary-900; }
    .colorable-fill.becomes-transparent { @apply fill-transparent; }

    &:not(nav) {
      h1, h2, h3, h4, h5, h6 { @apply text-white; }

      p, li {
        @apply
          [text-shadow:0.025em_0.025em_0.03em_rgba(0,0,0,0.2)]
          text-primary-200;
      }

      p > a,
      .text-link {
        @apply text-white hover:opacity-75 underline;
      }
    }

    .button.secondary { @apply text-white; }
  }

  subsection {
    @apply block py-60px md:py-20 w-full;

    &:first-of-type { @apply pt-0; }
    &:last-of-type { @apply pb-0; }
  }

  text-block {
    @apply max-w-5xl;

    h2 + p { @apply mt-[1.5em]; }
    & > p { @apply text-lg text-pretty; }
  }

  table-wrapper {
    @apply
      before:absolute
      before:h-full
      before:left-0
      before:pointer-events-none
      before:ring-1
      before:ring-inset
      before:ring-slate-900/10
      before:rounded-lg
      before:top-0
      before:w-full
      before:z-1
      block
      overflow-hidden
      relative
      rounded-lg;
  }
}

@layer utilities {
  .card-border {
    @apply rounded-lg shadow-slate-950/15 ring-1 ring-slate-950/[0.03];
  }

  .center-absolute {
    @apply left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2;
  }

  .hover-bg {
    @apply hover:bg-slate-50 active:bg-slate-100;
  }

  .hover-bg-scale {
    @apply
      before:absolute
      before:bg-slate-50
      before:duration-100
      before:left-0
      before:opacity-0
      before:scale-80
      before:size-full
      before:top-0
      before:-z-1
      before:rounded-[inherit]
      hover:before:opacity-100
      hover:before:scale-100
      relative;
  }

  .hover-bg-dark {
    @apply hover:bg-primary-200/10 active:bg-primary-200/20;
  }

  /* --eased-mask-gradient-steps is defined per class since it needs to be recalculated every time --mask-distance is redefined */

  .mask-b {
    --mask-offset: 0%;
    --eased-mask-gradient-steps:
      hsla(0, 0%, 0%, 0) var(--mask-offset),
      hsla(0, 0%, 0%, 0.013) calc(var(--mask-offset) + .094 * var(--mask-distance)),
      hsla(0, 0%, 0%, 0.049) calc(var(--mask-offset) + .176 * var(--mask-distance)),
      hsla(0, 0%, 0%, 0.104) calc(var(--mask-offset) + .248 * var(--mask-distance)),
      hsla(0, 0%, 0%, 0.175) calc(var(--mask-offset) + .312 * var(--mask-distance)),
      hsla(0, 0%, 0%, 0.259) calc(var(--mask-offset) + .37 * var(--mask-distance)),
      hsla(0, 0%, 0%, 0.352) calc(var(--mask-offset) + .424 * var(--mask-distance)),
      hsla(0, 0%, 0%, 0.45) calc(var(--mask-offset) + .475 * var(--mask-distance)),
      hsla(0, 0%, 0%, 0.55) calc(var(--mask-offset) + .525 * var(--mask-distance)),
      hsla(0, 0%, 0%, 0.648) calc(var(--mask-offset) + .576 * var(--mask-distance)),
      hsla(0, 0%, 0%, 0.741) calc(var(--mask-offset) + .63 * var(--mask-distance)),
      hsla(0, 0%, 0%, 0.825) calc(var(--mask-offset) + .688 * var(--mask-distance)),
      hsla(0, 0%, 0%, 0.896) calc(var(--mask-offset) + .752 * var(--mask-distance)),
      hsla(0, 0%, 0%, 0.951) calc(var(--mask-offset) + .824 * var(--mask-distance)),
      hsla(0, 0%, 0%, 0.987) calc(var(--mask-offset) + .906 * var(--mask-distance)),
      hsl(0, 0%, 0%) calc(var(--mask-offset) + var(--mask-distance));

    mask-image: linear-gradient(to top, var(--eased-mask-gradient-steps));
  }

  .mask-x {
    --mask-offset: 0%;
    --eased-mask-gradient-steps:
      hsla(0, 0%, 0%, 0) var(--mask-offset),
      hsla(0, 0%, 0%, 0.013) calc(var(--mask-offset) + .094 * var(--mask-distance)),
      hsla(0, 0%, 0%, 0.049) calc(var(--mask-offset) + .176 * var(--mask-distance)),
      hsla(0, 0%, 0%, 0.104) calc(var(--mask-offset) + .248 * var(--mask-distance)),
      hsla(0, 0%, 0%, 0.175) calc(var(--mask-offset) + .312 * var(--mask-distance)),
      hsla(0, 0%, 0%, 0.259) calc(var(--mask-offset) + .37 * var(--mask-distance)),
      hsla(0, 0%, 0%, 0.352) calc(var(--mask-offset) + .424 * var(--mask-distance)),
      hsla(0, 0%, 0%, 0.45) calc(var(--mask-offset) + .475 * var(--mask-distance)),
      hsla(0, 0%, 0%, 0.55) calc(var(--mask-offset) + .525 * var(--mask-distance)),
      hsla(0, 0%, 0%, 0.648) calc(var(--mask-offset) + .576 * var(--mask-distance)),
      hsla(0, 0%, 0%, 0.741) calc(var(--mask-offset) + .63 * var(--mask-distance)),
      hsla(0, 0%, 0%, 0.825) calc(var(--mask-offset) + .688 * var(--mask-distance)),
      hsla(0, 0%, 0%, 0.896) calc(var(--mask-offset) + .752 * var(--mask-distance)),
      hsla(0, 0%, 0%, 0.951) calc(var(--mask-offset) + .824 * var(--mask-distance)),
      hsla(0, 0%, 0%, 0.987) calc(var(--mask-offset) + .906 * var(--mask-distance)),
      hsl(0, 0%, 0%) calc(var(--mask-offset) + var(--mask-distance));

    /* Mirrored eased gradient for the opposite edge */
    --eased-mask-gradient-steps-inverse:
      hsl(0, 0%, 0%) calc(100% - var(--mask-offset) - var(--mask-distance)),
      hsla(0, 0%, 0%, 0.987) calc(100% - var(--mask-offset) - (.906 * var(--mask-distance))),
      hsla(0, 0%, 0%, 0.951) calc(100% - var(--mask-offset) - (.824 * var(--mask-distance))),
      hsla(0, 0%, 0%, 0.896) calc(100% - var(--mask-offset) - (.752 * var(--mask-distance))),
      hsla(0, 0%, 0%, 0.825) calc(100% - var(--mask-offset) - (.688 * var(--mask-distance))),
      hsla(0, 0%, 0%, 0.741) calc(100% - var(--mask-offset) - (.63 * var(--mask-distance))),
      hsla(0, 0%, 0%, 0.648) calc(100% - var(--mask-offset) - (.576 * var(--mask-distance))),
      hsla(0, 0%, 0%, 0.55) calc(100% - var(--mask-offset) - (.525 * var(--mask-distance))),
      hsla(0, 0%, 0%, 0.45) calc(100% - var(--mask-offset) - (.475 * var(--mask-distance))),
      hsla(0, 0%, 0%, 0.352) calc(100% - var(--mask-offset) - (.424 * var(--mask-distance))),
      hsla(0, 0%, 0%, 0.259) calc(100% - var(--mask-offset) - (.37 * var(--mask-distance))),
      hsla(0, 0%, 0%, 0.175) calc(100% - var(--mask-offset) - (.312 * var(--mask-distance))),
      hsla(0, 0%, 0%, 0.104) calc(100% - var(--mask-offset) - (.248 * var(--mask-distance))),
      hsla(0, 0%, 0%, 0.049) calc(100% - var(--mask-offset) - (.176 * var(--mask-distance))),
      hsla(0, 0%, 0%, 0.013) calc(100% - var(--mask-offset) - (.094 * var(--mask-distance))),
      hsla(0, 0%, 0%, 0) calc(100% - var(--mask-offset));

    mask-image: linear-gradient(
      to right,
      var(--eased-mask-gradient-steps),
      hsl(0, 0%, 0%) 50%,
      var(--eased-mask-gradient-steps-inverse)
    );
  }

  .tooltip-term {
    @apply
      after:absolute
      after:border-dotted
      after:border-primary-300
      after:border-b-[calc(1em/6)]
      after:bottom-[-0.0625em]
      after:left-1/2
      after:-translate-x-1/2
      after:w-[calc(100%-0.125em)]
      cursor-help
      duration-100
      font-medium
      hover:text-primary-600
      relative
      text-primary-500
      transition-colors;
  }

  .text-link,
  p > a {
    @apply text-primary-500 font-semibold duration-100 hover:text-primary-700 cursor-pointer underline;
  }
}
